.indicator {
  width: 16px;
  height: 5px;
  transition: width 250ms ease;

  &[data-active] {
    width: 50px;
  }
}

.control {
  &[data-inactive] {
    cursor: default;
  }

  background-color: transparent;
  border-color: transparent;
}